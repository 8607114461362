import batchData from './batchRepository'
import machineData from './machineRepository'
import printerData from './printerRepository'
import eventData from './eventRepository'
import officeData from './officeRepository'
import templateData from './templateRepository'
import userData from './userRepository'

const repositories = {
  batchData, machineData, printerData, templateData, userData, officeData, eventData
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
