import getNetResource from './netConfig'

const batchData = {
  archive(config) {
    return getNetResource('/batches/archive', 'post', config)
  },
  addEmployee(newUser) {
    return getNetResource('/data-uploads/uploadUser', 'post', newUser)
  },
  cancelEmails({batchId}) {
    return getNetResource(`/batches/${batchId}/cancel-batch-email`, 'post')
  },
  createPhotoEmployee({ photo, uuid, batchId }){
    let formData = new FormData()
    formData.append('userPhoto', photo)
    formData.append('userUuid', uuid)
    formData.append('batchId', batchId)
    return getNetResource('/data-uploads/uploadUserPhoto', 'post', formData)
  },
  create({ batchName, templateId, filename, data, userSystemAccountName, userSystemUserType }) {
    let formData = new FormData()
    formData.append('dataFile', data)
    formData.append('dataUploadName', batchName)
    formData.append('filename', filename)
    formData.append('templateId', templateId)
    formData.append('userSystemAccountName', userSystemAccountName)
    formData.append('userSystemUserType', userSystemUserType)

    return getNetResource('/data-uploads', 'post', formData)
  },
  createPhotos({ photos, batchId, link }) {
    let formData = new FormData()
    formData.append('imagesFile', photos)
    if(link==null)
      link=''
    formData.append('linkData', link)
    return getNetResource(`/data-uploads/${batchId}/images`, 'post', formData)
  },
  createLink(config) {
    return getNetResource('/templates/createLink', 'post', config)
  },
  createSingleUserLink(config) {
    return getNetResource('/templates/createSingleUserLink', 'post', config)
  },
  dontPrintCard({userId}) {
    return getNetResource('/batches/dontPrintCard', 'post', {userId})
  },
  resendBatchEmails(config) {
    return getNetResource('/data-uploads/resendTemporaryPassword', 'post', config)
  },
  sendBatchEmails(config) {
    return getNetResource('/data-uploads/sendTemporaryPassword', 'post', config)
  },
  sendSingleUserEmails({ users, email }) {
    return getNetResource('/data-uploads/send-single-temp-pass', 'post', { users, ...email })
  },
  sendMessage(config) {
    return getNetResource('/data-uploads/sendMessage', 'post', config)
  },
  sendUsersUpdateEmail(config) {
    return getNetResource('/data-uploads/sendUsersUpdateEmail', 'post', config)
  },
  getBatchFields(idBatch){
    return getNetResource(`/data-uploads/getDataFields/${idBatch}`, 'get')
  },
  deleteEmployee(userUuid) {
    return getNetResource(`/users/${userUuid}/deactivate`, 'get')
  },
  deleteEmployeePermanently(userUuid) {
    return getNetResource(`/users/${userUuid}/deleteUserPermanently`, 'post')
  },
  deleteSelectedEmployee(args) {
    return getNetResource(`/users/deactivateSelected`, 'post', args)
  },
  activateSelectedEmployee(args) {
    return getNetResource(`/users/activateSelected`, 'post', args)
  },
  sendReminder(config) {
    return getNetResource(`/users/sendReminderSelected`, 'post', config)
  },
  sendReminderAll(config) {
    return getNetResource(`/users/sendReminderAll`, 'post', config)
  },
  enqueueCard({userId}) {
    return getNetResource('/batches/enqueueCard', 'post', {userId})
  }, 
  getBatchesFilter(config) {
    return getNetResource('/batches/getBatches', 'post', config)
  },
  getBatchesFilterApprover(config) {
    return getNetResource('/batches/getBatchesApprover', 'post', config)
  },
  getBatchesFilterSupervisor(config) {
    return getNetResource('/batches/getBatchesSupervisor', 'post', config)
  },
  getDetailsFilter(config) {
    return getNetResource('/batches/getListUserBatch', 'post', config)
  },
  getPrintDetailsFilter(config) {
    return getNetResource('/batches/getListUserPrintBatch', 'post', config)
  },
  getAmountUsers(config) {
    return getNetResource('/batches/getAmountUsers', 'post', config)
  },
  getAmountUsersPrint(config) {
    return getNetResource('/batches/getAmountUsersPrint', 'post', config)
  },
  getBatchesSummary(config) {
    return getNetResource('/batches/getBatchesSummary', 'post', config)
  },
  downloadSummary(config) {
    return getNetResource('/batches/downloadSummary', 'post', config)
  },
  getPrintsReport() {
    return getNetResource('/prints/get-print-history', 'get')
  },
  getKeyReport() {
    return getNetResource('/users/get-key-report', 'get')
  },
  getEventRecoverReport() {
    return getNetResource('/users/get-event-recover-report', 'get')
  },
  getAboutInfo() {
    return getNetResource('/users/get-about-info', 'get')
  },
  getSentTempPasswordErrors(batchId) {
    return getNetResource(`/data-uploads/${batchId}/getSentTempPasswordErrors`, 'get')
  },
  getUploadErrors(batchId) {
    return getNetResource(`/data-uploads/${batchId}/errors`, 'get')
  },
  getResumeBatch(batchId) {
    return getNetResource(`/data-uploads/${batchId}/resume-batch`, 'get')
  },
  getSentEmailStatus(batchId) {
    return getNetResource(`/data-uploads/${batchId}/getSentEmailStatus`, 'get')
  },
  printBatch(batchId) {
    return getNetResource(`/prints/${batchId}`, 'post')
  },
  printBatches(listBatchId) {
    return getNetResource(`/prints/`, 'post',listBatchId)
  },
  recover(config) {
    return getNetResource('/batches/recover', 'post', config)
  },
  recoverEmployee(employeeId) {
    return getNetResource(`/users/${employeeId}/activate`, 'get')
  },
  forceApprovalEmployee(args) {
    return getNetResource(`/users/forceApproval`, 'post', args)
  },
  resendInvitation(employeeId) {
    return getNetResource(`/users/${employeeId}/resendInvitation`, 'post')
  },
  reprintCard(userId) {
    return getNetResource('/batches/reprintCard', 'post', {userId})
  },
  stopPrintBatch(batchId) {
    return getNetResource(`/batches/${batchId}/status/STOP`, 'post')
  },
  removePrintBatch(batchId) {
    return getNetResource(`/batches/${batchId}/status/DISCARDED`, 'post')
  },
  cancelStopPrintBatch(batchId) {
    return getNetResource(`/batches/${batchId}/status/GO`, 'post')
  },
  resumePrintBatch(batchId) {
    return getNetResource(`/batches/${batchId}/resume`, 'post')
  },
  getEmployeesFilter(config) {
    return getNetResource('/batches/getUsersFilter', 'post', config)
  },
  getEmployeesUnansweredFilter(config) {
    return getNetResource('/batches/getUsersUnansweredFilter', 'post', config)
  },
  getProgressReminder({accountName,userType}) {
    return getNetResource(`/users/progress-reminder/${accountName}/userType/${userType}`, 'get')
  },
  updateEmployee(newUser) {
    return getNetResource('/data-uploads/updateUser', 'post', newUser)
  },
  recoverEvents(hours) {
    let formData = new FormData()
    formData.append('hours', hours)
    return getNetResource(`/users/recover-events`, 'post',formData)
  },
  updateEmployeePhoto({ photo, uuid, batchId }){
    let formData = new FormData()
    formData.append('userPhoto', photo)
    formData.append('userUuid', uuid)
    formData.append('batchId', batchId)
    return getNetResource('/data-uploads/uploadUserPhoto', 'post', formData)
  },
}

export default batchData
  